<template>
  <div style="width: 100%;height: 100vh;background-color: rgb(8, 20, 32);overflow: hidden">
    <div style="position: absolute;left: 50%;top: 2%;margin-left: -20%">
      <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/show_finace.png" style="width: 80%">
    </div>
    <!--    头部-->
    <div style="height: 100px;display: flex;justify-content: space-between">
      <!--      日期选择-->
      <div style="padding-top: 15px;padding-left: 50px">
        <el-date-picker
            v-model="time"
            type="month"
            placeholder="选择日期"
            format="YYYY-MM"
            value-format="YYYY-MM"
            @change="changeGetManagementByMonth"
            size="mini"
            style="color: #fff"
        >
        </el-date-picker>

      </div>

      <!--      时间-->
      <div id="timestyle">
        {{ timenow }}
      </div>
    </div>
    <div style="position:absolute;top: 1.8vh;left: 20vh;">
      <div style="color: #FF0000;margin-bottom: 1vh" v-if="radio1 === '1'" @click="radio1 = '1'">本级管理费</div>
      <div style="color: #FFFFFF;margin-bottom: 1vh" v-if="radio1 === '2'" @click="radio1 = '1'">本级管理费</div>
      <div style="color: #FF0000" v-if="radio1 === '2'" @click="radio1 = '2'">其他直接费</div>
      <div style="color: #FFFFFF" v-if="radio1 === '1'" @click="radio1 = '2'">其他直接费</div>
    </div>
<!--    本级管理费-->
    <div v-if="radio1 === '1'">
      <!--    主要内容-->
      <div style=";width: 95%;margin: 2% auto auto auto">
        <!--      总支出-->
        <div style="width: 50%;margin: auto;;text-align: center;color: #FFFFFF;display: flex;justify-content: center">
          <span style="color: #FFFFFF;font-size: 3em;">开支总额</span>
          <div style="color: #18ebff;font-size: 3.5em;margin-left: 11%;font-weight: bolder">{{
              manageList.allPayNum
            }}
          </div>
        </div>
        <div
            style="background: -webkit-linear-gradient(left, #020e1a -4%, #18ebff 50%, #020e1a 100%);height: 0.2%;width: 16%;position: absolute;left: 48.5%"></div>
      </div>
      <!--    分项数据-->
      <!--    分项数据一-->
      <div style="display: flex;width: 95%;margin: 4% auto auto auto;height: 10vh">
        <div class="data_divs">
          <div class="type_divs">
            正式工、合同工薪酬
          </div>
          <div class="num_divs">
            {{ manageList.salaryNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(3)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            福利费
          </div>
          <div class="num_divs">
            {{ manageList.welfareNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(4)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            食堂开支
          </div>
          <div class="num_divs">
            {{ manageList.diningroomNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(5)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            工资附加费
          </div>
          <div class="num_divs">
            {{ manageList.otherSalaryNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(6)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            临时用工
          </div>
          <div class="num_divs">
            {{ manageList.tenporaryNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(7)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            折旧费
          </div>
          <div class="num_divs">
            {{ manageList.depreciationNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(8)">查看详情</el-button>
        </div>
        <div class="data_last_divs">
          <div class="type_divs">
            办公费
          </div>
          <div class="num_divs">
            {{ manageList.workNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(9)">查看详情</el-button>
        </div>
      </div>

      <!--    分项数据二-->
      <div style="display: flex;width: 95%;margin: 6% auto auto auto;height: 10vh">
        <div class="data_divs">
          <div class="type_divs">
            差旅费
          </div>
          <div class="num_divs">
            {{ manageList.travelNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(10)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            劳动保护费
          </div>
          <div class="num_divs">
            {{ manageList.safetyNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(11)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            会议费
          </div>
          <div class="num_divs">
            {{ manageList.meetNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(12)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            维修费
          </div>
          <div class="num_divs">
            {{ manageList.repairNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(13)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            水电气暖燃煤费
          </div>
          <div class="num_divs">
            {{ manageList.waterAndCableNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(14)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            租赁费
          </div>
          <div class="num_divs">
            {{ manageList.rentNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(15)">查看详情</el-button>
        </div>
        <div class="data_last_divs">
          <div class="type_divs">
            通讯费
          </div>
          <div class="num_divs">
            {{ manageList.communicationNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(16)">查看详情</el-button>
        </div>
      </div>


      <!--    分项数据三-->
      <div style="display: flex;width: 95%;margin: 6% auto auto auto;height: 10vh">
        <div class="data_divs">
          <div class="type_divs">
            车辆使用维护费
          </div>
          <div class="num_divs">
            {{ manageList.carNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(17)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            企业文化建设费
          </div>
          <div class="num_divs">
            {{ manageList.adNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(18)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            物业管理费
          </div>
          <div class="num_divs">
            {{ manageList.porpertyNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(19)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            无形资产摊销
          </div>
          <div class="num_divs">
            {{ manageList.intangibleAssetsNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(20)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            党建经费
          </div>
          <div class="num_divs">
            {{ manageList.partyBuildNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(21)">查看详情</el-button>
        </div>
        <div class="data_divs">
          <div class="type_divs">
            其他费用
          </div>
          <div class="num_divs">
            {{ manageList.otherNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(22)">查看详情</el-button>
        </div>
        <div class="data_last_divs">
          <div class="type_divs">
            前期开办费
          </div>
          <div class="num_divs">
            {{ manageList.earlyNum }}
          </div>
          <el-button type="success" round class="buttons_style" @click="getDetailMessage(23)">查看详情</el-button>
        </div>
      </div>
      <!--组织选择-->
      <div style="margin-left: 5vh;margin-top: 18vh;display: flex">
        <div class="div1">
          <div class="div2" v-if="organ_name !== '汇总' " @click="getDataByBdst('汇总')">汇总</div>
          <div class="div3" v-if="organ_name === '汇总'" @click="getDataByBdst('汇总')">汇总</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '中铁十二局集团CZ铁路项目公司'"
               @click="getDataByBdst('中铁十二局集团CZ铁路项目公司')">项目公司
          </div>
          <div class="div3" v-if="organ_name === '中铁十二局集团CZ铁路项目公司'"
               @click="getDataByBdst('中铁十二局集团CZ铁路项目公司')">项目公司
          </div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '2标一工区'" @click="getDataByBdst('2标一工区')">二标一工区</div>
          <div class="div3" v-if="organ_name === '2标一工区'" @click="getDataByBdst('2标一工区')">二标一工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '2标二工区'" @click="getDataByBdst('2标二工区')">二标二工区</div>
          <div class="div3" v-if="organ_name === '2标二工区'" @click="getDataByBdst('2标二工区')">二标二工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标一工区'" @click="getDataByBdst('5标一工区')">五标一工区</div>
          <div class="div3" v-if="organ_name === '5标一工区'" @click="getDataByBdst('5标一工区')">五标一工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标二工区'" @click="getDataByBdst('5标二工区')">五标二工区</div>
          <div class="div3" v-if="organ_name === '5标二工区'" @click="getDataByBdst('5标二工区')">五标二工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标三工区'" @click="getDataByBdst('5标三工区')">五标三工区</div>
          <div class="div3" v-if="organ_name === '5标三工区'" @click="getDataByBdst('5标三工区')">五标三工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标四工区'" @click="getDataByBdst('5标四工区')">五标四工区</div>
          <div class="div3" v-if="organ_name === '5标四工区'" @click="getDataByBdst('5标四工区')">五标四工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标五工区'" @click="getDataByBdst('5标五工区')">五标五工区</div>
          <div class="div3" v-if="organ_name === '5标五工区'" @click="getDataByBdst('5标五工区')">五标五工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标六工区'" @click="getDataByBdst('5标六工区')">五标六工区</div>
          <div class="div3" v-if="organ_name === '5标六工区'" @click="getDataByBdst('5标六工区')">五标六工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标七工区'" @click="getDataByBdst('5标七工区')">五标七工区</div>
          <div class="div3" v-if="organ_name === '5标七工区'" @click="getDataByBdst('5标七工区')">五标七工区</div>
        </div>

      </div>
      <!--详细信息-->
      <div>
        <div v-if="dialogVisible" id="dialog_div">
          <div style="position: absolute;top: 0.5vh;right: 1vh" @click="close_dialog()">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/close.png">
          </div>
          <div id="dialog_detail">
            <div id="myEchartsDetail" style="width: 100%;height: 120%;margin-right: 50px;margin-top: -5vh"></div>
          </div>
        </div>
      </div>
    </div>
<!--    其他直接费-->
    <div v-if="radio1 === '2'">
      <!--    主要内容-->
      <div style=";width: 95%;margin: 2% auto auto auto">
        <!--      总支出-->
        <div style="width: 50%;margin: auto;;text-align: center;color: #FFFFFF;display: flex;justify-content: center">
          <span style="color: #FFFFFF;font-size: 3em;">开支总额</span>
          <div style="color: #18ebff;font-size: 3.5em;margin-left: 11%;font-weight: bolder">{{ otherManageList.total }}</div>
        </div>
        <div
            style="background: -webkit-linear-gradient(left, #020e1a -4%, #18ebff 50%, #020e1a 100%);height: 0.2%;width: 16%;position: absolute;left: 48.5%"></div>
      </div>
      <!--    分项数据-->
      <!--    分项数据一-->
      <div style="display: flex;width: 95%;margin: 2% auto auto auto;height: 10vh">
        <div class="data_divs_other">
          <div class="type_divs">
            其他直接费
          </div>
          <div class="num_divs">
            {{ otherManageList.otherDirectCosts }}
          </div>
          <div @click="getDetailMessage2(1)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            物料消耗
          </div>
          <div class="num_divs">
            {{ otherManageList.materialConsumption }}
          </div>
          <div @click="getDetailMessage2(2)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            租赁费
          </div>
          <div class="num_divs">
            {{ otherManageList.rentalFees }}
          </div>
          <div @click="getDetailMessage2(3)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            运输费
          </div>
          <div class="num_divs">
            {{ otherManageList.shippingFee }}
          </div>
          <div @click="getDetailMessage2(4)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            修理费
          </div>
          <div class="num_divs">
            {{ otherManageList.repairFee }}
          </div>
          <div @click="getDetailMessage2(5)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            临时设施摊销
          </div>
          <div class="num_divs">
            {{ otherManageList.temporaryFacilityAmortization }}
          </div>
          <div @click="getDetailMessage2(6)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_last_divs_other">
          <div class="type_divs">
            测量费
          </div>
          <div class="num_divs">
            {{ otherManageList.measurementFee }}
          </div>
          <div  @click="getDetailMessage2(7)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
      </div>

      <!--    分项数据二-->
      <div style="display: flex;width: 95%;margin: 4% auto auto auto;height: 10vh">
        <div class="data_divs_other">
          <div class="type_divs">
            检验试验费
          </div>
          <div class="num_divs">
            {{ otherManageList.inspectionAndTestFee }}
          </div>
          <div @click="getDetailMessage2(8)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            文明工地费
          </div>
          <div class="num_divs">
            {{ otherManageList.civilizedConstructionSiteFee }}
          </div>
          <div @click="getDetailMessage2(9)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            环保(绿化排污)费
          </div>
          <div class="num_divs">
            {{ otherManageList.environmentalProtectionFee }}
          </div>
          <div @click="getDetailMessage2(10)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            工程用水用电
          </div>
          <div class="num_divs">
            {{ otherManageList.engineeringWaterAndElectricity }}
          </div>
          <div @click="getDetailMessage2(11)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            咨询服务费
          </div>
          <div class="num_divs">
            {{ otherManageList.consultingServiceFee }}
          </div>
          <div @click="getDetailMessage2(12)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            征地拆迁费
          </div>
          <div class="num_divs">
            {{ otherManageList.landAquisitionAndDemolitionFees }}
          </div>
          <div @click="getDetailMessage2(13)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_last_divs_other">
          <div class="type_divs">
            勘察设计费
          </div>
          <div class="num_divs">
            {{ otherManageList.surveyAndDesignFee }}
          </div>
          <div @click="getDetailMessage2(14)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
      </div>


      <!--    分项数据三-->
      <div style="display: flex;width: 95%;margin: 4% auto auto auto;height: 10vh">
        <div class="data_divs_other">
          <div class="type_divs">
            配合施工费
          </div>
          <div class="num_divs">
            {{ otherManageList.withTheConstructionCost }}
          </div>
          <div @click="getDetailMessage2(15)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            工程保险费
          </div>
          <div class="num_divs">
            {{ otherManageList.engineeringInsurance }}
          </div>
          <div @click="getDetailMessage2(16)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            劳动保护费
          </div>
          <div class="num_divs">
            {{ otherManageList.labourProtectionFee }}
          </div>
          <div @click="getDetailMessage2(17)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            青苗补偿费
          </div>
          <div class="num_divs">
            {{ otherManageList.youngCropCompensation }}
          </div>
          <div @click="getDetailMessage2(18)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            防疫费
          </div>
          <div class="num_divs">
            {{ otherManageList.epidemicPreventionFee }}
          </div>
          <div  @click="getDetailMessage2(19)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            安全生产费用
          </div>
          <div class="num_divs">
            {{ otherManageList.safetyProductionFee }}
          </div>
          <div @click="getDetailMessage2(20)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_last_divs_other">
          <div class="type_divs">
            其他
          </div>
          <div class="num_divs">
            {{ otherManageList.other }}
          </div>
          <div @click="getDetailMessage2(21)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
      </div>

      <!--    分项数据四-->
      <div style="display: flex;width: 95%;margin: 4% auto auto auto;height: 10vh">
        <div class="data_divs_other">
          <div class="type_divs">
            其他费用
          </div>
          <div class="num_divs">
            {{ otherManageList.otherFee }}
          </div>
          <div @click="getDetailMessage2(22)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            税金及附加
          </div>
          <div class="num_divs">
            {{ otherManageList.taxesAndSurcharges }}
          </div>
          <div @click="getDetailMessage2(23)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            财务费用
          </div>
          <div class="num_divs">
            {{ otherManageList.financialExpenses }}
          </div>
          <div @click="getDetailMessage2(24)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            管理费用
          </div>
          <div class="num_divs">
            {{ otherManageList.managementFees }}
          </div>
          <div @click="getDetailMessage2(25)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
        <div class="data_divs_other">
          <div class="type_divs">
            所得税费用
          </div>
          <div class="num_divs">
            {{ otherManageList.incomeTaxExpense }}
          </div>
          <div @click="getDetailMessage2(26)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="position:absolute;top: 1.7vh;right: 1vh">
          </div>
        </div>
      </div>
      <!--组织选择-->
      <div style="margin-left: 5vh;margin-top: 11vh;display: flex">
        <div class="div1">
          <div class="div2" v-if="organ_name !== '汇总' " @click="getDataByBdst('汇总')">汇总</div>
          <div class="div3" v-if="organ_name === '汇总'" @click="getDataByBdst('汇总')">汇总</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '中铁十二局集团CZ铁路项目公司'"
               @click="getDataByBdst('中铁十二局集团CZ铁路项目公司')">项目公司
          </div>
          <div class="div3" v-if="organ_name === '中铁十二局集团CZ铁路项目公司'"
               @click="getDataByBdst('中铁十二局集团CZ铁路项目公司')">项目公司
          </div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '2标一工区'" @click="getDataByBdst('2标一工区')">二标一工区</div>
          <div class="div3" v-if="organ_name === '2标一工区'" @click="getDataByBdst('2标一工区')">二标一工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '2标二工区'" @click="getDataByBdst('2标二工区')">二标二工区</div>
          <div class="div3" v-if="organ_name === '2标二工区'" @click="getDataByBdst('2标二工区')">二标二工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标一工区'" @click="getDataByBdst('5标一工区')">五标一工区</div>
          <div class="div3" v-if="organ_name === '5标一工区'" @click="getDataByBdst('5标一工区')">五标一工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标二工区'" @click="getDataByBdst('5标二工区')">五标二工区</div>
          <div class="div3" v-if="organ_name === '5标二工区'" @click="getDataByBdst('5标二工区')">五标二工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标三工区'" @click="getDataByBdst('5标三工区')">五标三工区</div>
          <div class="div3" v-if="organ_name === '5标三工区'" @click="getDataByBdst('5标三工区')">五标三工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标四工区'" @click="getDataByBdst('5标四工区')">五标四工区</div>
          <div class="div3" v-if="organ_name === '5标四工区'" @click="getDataByBdst('5标四工区')">五标四工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标五工区'" @click="getDataByBdst('5标五工区')">五标五工区</div>
          <div class="div3" v-if="organ_name === '5标五工区'" @click="getDataByBdst('5标五工区')">五标五工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标六工区'" @click="getDataByBdst('5标六工区')">五标六工区</div>
          <div class="div3" v-if="organ_name === '5标六工区'" @click="getDataByBdst('5标六工区')">五标六工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标七工区'" @click="getDataByBdst('5标七工区')">五标七工区</div>
          <div class="div3" v-if="organ_name === '5标七工区'" @click="getDataByBdst('5标七工区')">五标七工区</div>
        </div>

      </div>
      <!--详细信息-->
      <div>
        <div v-if="dialogVisible" id="dialog_div">
          <div style="position: absolute;top: 0.5vh;right: 1vh" @click="close_dialog()">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/close.png">
          </div>
          <div id="dialog_detail">
            <div id="myEchartsDetail" style="width: 100%;height: 120%;margin-right: 50px;margin-top: -5vh">sad</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getPreMonth, setNowMonth2, setNowTimesInHole} from "@/utils/time";

export default {
  name: "show_finace",
  data() {
    return {
      time: '',
      timenow: '',
      timer: null,
      manageList: [],
      organ_name: '',
      dialogVisible: false,
      sort: '',
      radio1: '1',
      otherManageList: [],
      sort2: ''
    }
  },
  created() {
    this.organ_name = '汇总';
    this.time = getPreMonth(setNowMonth2());
    setTimeout(this.getManagementByMonth, 100);
    setTimeout(this.getOtherManagement, 100);
  },
  methods: {
    getManagementByMonth() {
      this.axios.post('https://www.gjegm.ltd/showdata/czManagementFeeBudget/getManagementByMonth', (response) => {
        this.manageList = response.obj;
      }, {
        month: this.time,
        organName: this.organ_name
      })
    },
    changeGetManagementByMonth() {
      this.getManagementByMonth();
      this.getOtherManagement();
    },
    getDataByBdst(val) {
      this.organ_name = val;
      this.getManagementByMonth();
      this.getOtherManagement();
    },
    getDetailMessage(val) {
      this.sort = val;
      this.dialogVisible = true;
      setTimeout(this.getdatail, 100);
    },
    getdatail() {
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例

      const myChart = this.$echarts.init(document.getElementById('myEchartsDetail'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('https://www.gjegm.ltd/showdata/czManagementFeeBudget/getDetailMessage', (response) => {
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          for (let i = 0; i < response.obj.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[i]);
            this.sumMile = this.sumMile * 1 + response.obj2[i] * 1;
          }
          this.sumMile = this.sumMile.toFixed(2);
          // 防止返回图表不重新加载
          document.getElementById('myEchartsDetail').setAttribute('_echarts_instance_', '');
          // 绘制图表
          myChart.setOption({
            title: [
              {
                text: '单位: 元',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 23
                },
                top: 25,
                left: 5,
              }
            ],
            legend: {
              textStyle: {
                color: '#ffffff'
              },
              padding: 0,
              top: '10%',
              left: '75%'
            },
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 18,
                color: '#fff',
              },

            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: true //y轴线消失
              },
              splitLine: {
                show: false
              }
            },
            series: [
              {
                name: '',
                data: y,
                type: 'line',
                barWidth: 50,
                barGap: '30%',
                color: {
                  type: 'bar',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgb(80, 180, 196)' // 0% 处的颜色
                  }, {
                    offset: 0.3, color: 'rgb(80, 180, 196)' // 100% 处的颜色
                  },
                    {
                      offset: 1, color: 'rgb(8, 20, 32)' // 100% 处的颜色
                    }],
                  global: false // 缺省为 false
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: 'rgb(241,189,5)',
                        fontSize: 50,
                        fontWeight: '50000'
                      },
                    },
                  }
                },
              }
            ]
          });
        }
      }, {
        organ_name: this.organ_name,
        month: this.time,
        sort: this.sort
      })
    },
    close_dialog() {
      this.dialogVisible = false
    },
    getDetailMessage2(val) {
      this.sort2 = val;
      this.dialogVisible = true;
      setTimeout(this.getOtherDetailMessage, 100);
    },
    getOtherManagement(){
      this.axios.post('https://www.gjegm.ltd/showdata/czOtherDirectExpenses/getOtherManage', (response) => {
        this.otherManageList = response.obj;
      },{
        month: this.time,
        organName: this.organ_name
      })
    },
    getOtherDetailMessage(){
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例

      const myChart = this.$echarts.init(document.getElementById('myEchartsDetail'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('https://www.gjegm.ltd/showdata/czOtherDirectExpenses/getDetailMessage', (response) => {
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          for (let i = 0; i < response.obj.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[i]);
            this.sumMile = this.sumMile * 1 + response.obj2[i] * 1;
          }
          this.sumMile = this.sumMile.toFixed(2);
          // 防止返回图表不重新加载
          document.getElementById('myEchartsDetail').setAttribute('_echarts_instance_', '');
          // 绘制图表
          myChart.setOption({
            title: [
              {
                text: '单位: 元',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 23
                },
                top: 25,
                left: 5,
              }
            ],
            legend: {
              textStyle: {
                color: '#ffffff'
              },
              padding: 0,
              top: '10%',
              left: '75%'
            },
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 18,
                color: '#fff',
              },

            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: true //y轴线消失
              },
              splitLine: {
                show: false
              }
            },
            series: [
              {
                name: '',
                data: y,
                type: 'line',
                barWidth: 50,
                barGap: '30%',
                color: {
                  type: 'bar',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgb(80, 180, 196)' // 0% 处的颜色
                  }, {
                    offset: 0.3, color: 'rgb(80, 180, 196)' // 100% 处的颜色
                  },
                    {
                      offset: 1, color: 'rgb(8, 20, 32)' // 100% 处的颜色
                    }],
                  global: false // 缺省为 false
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: 'rgb(241,189,5)',
                        fontSize: 50,
                        fontWeight: '50000'
                      },
                    },
                  }
                },
              }
            ]
          });
        }
      }, {
        organ_name: this.organ_name,
        month: this.time,
        sort: this.sort2
      })
    }

  },
  mounted() {
    this.timer = setInterval(() => {
      this.timenow = setNowTimesInHole(new Date())
    }, 1000)

  }
}
</script>

<style scoped>
@import '../../../common/css/show_finace.css';

::v-deep .el-input__inner {
  background-color: rgb(12, 37, 48);
  height: 50px;
  line-height: 50px;
  width: 150px;
  font-size: 15px;
  border: 2px solid RGB(48, 84, 95);
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

::v-deep .el-date-editor .el-input__icon:nth-child(1) {
  display: none;
}
/* 选中后的字体颜色 */
.el-radio__input.is-checked + .el-radio__label {
  color: #d4285c !important;
}
/* 选中后小圆点的颜色 */
.el-radio__input.is-checked .el-radio__inner {
  background: #28d4c1 !important;
  border-color: #28d4c1 !important;
}
</style>